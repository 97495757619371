import React from 'react';
import ContactContent from '../../components/ContactContent';
import Layout from '../../components/layout';

const Anfahrt = ({ data, location, ...props }) => {
    return (
        <Layout location={location} title="Anfahrt" noSlider {...props}>
            <ContactContent />
        </Layout>
    );
};

export default Anfahrt;
